import { ProgramConfig } from '@wsb_dev/datafi-shared/lib/types';
import { DialogService } from 'aurelia-dialog';
import { autoinject } from 'aurelia-framework';
import { ConfirmDialog } from '../../components/confirm-dialog/confirm-dialog';
import { DatafiProAPI } from '../../services/api/DatafiProAPI';
import { AlertService } from '../../services/util/Alert';
import { StoreManager } from '../../services/util/StoreManager';
import { ensureNumber } from '../../util/numbers/ensureNumber';

@autoinject
export class ConfigStoreManager {

    programId: number;
    configs: ProgramConfig[];

    constructor(
        private api: DatafiProAPI,
        private dialogs: DialogService,
        private alerts: AlertService,
        private stores: StoreManager,
    ) { }

    activate(params, config) {
        this.programId = ensureNumber(config.settings.programId);
        this.stores.activate(this.programId);
    }

    delete(id: number) {
        return this.dialogs.open({
            viewModel: ConfirmDialog,
            model: {
                message: 'Are you sure you want to delete this data store? Existing dashboards may no longer function correctly.',
                title: 'Confirm Delete',
            } as Partial<ConfirmDialog>,
        })
            .whenClosed((result) => {
                if (result.wasCancelled) {
                    return;
                }
                return this.api.programConfigs.remove(id)
                    .then(() => {
                        this.alerts.create({
                            label: 'Data store removed',
                            level: 'success',
                            dismissable: true,
                        });
                        return this.stores.updateConfigs();
                    });
            })
            .catch((e) => {
                this.alerts.create({
                    label: `Error: ${e.message}`,
                    level: 'error',
                    dismissable: true,
                });
            });
    }

}
