import { autoinject, bindable, inject } from 'aurelia-framework';
import { createCustomEvent } from '../../util/events/createCustomEvent';

@autoinject
export class ColorPicker {
    @bindable color: string;
    colorChanged(){
        this.element.dispatchEvent(createCustomEvent('change', this.color, true));
    }
    @bindable showOpacity: boolean;
    basicColor: string;
    opacity: number;

    constructor(private element: Element){}

    attached() {
        if (this.color) {
            this.opacity = this.getAlphaFromHex(this.color);
            this.basicColor = this.formatBasicColor(this.color);
        }
    }
    // Function adapted from https://www.joshuamiron.com/percent-to-hex-converter
    percentToHex(valNum: number) {
        const decimalValue = Math.round(valNum * 255 / 100);
        let hexValue = '';
        if (valNum < 7) {
            hexValue = '0' + decimalValue.toString(16).toUpperCase();
        }
        else {
            hexValue = decimalValue.toString(16).toUpperCase();
        }
        return hexValue;
    }

    formatColor() {
        //If no opacity default to 100%
        const alpha = this.opacity ? this.percentToHex(this.opacity) : 'ff';
        const hexWithAlpha = this.basicColor.concat(alpha);
        this.color = hexWithAlpha;
    }

    formatBasicColor(color: string) {
        if (color.length === 9) {
            return color.slice(0, 7);
        } else {
            return color;
        }
    }

    getAlphaFromHex(hex) {
        //Assumes input value is an 8 character hex string with leading #
        if (hex.length !== 9) {
            return 100;
        } else {
            const alpha = hex.slice(7, 9);
            const percent = Math.round((parseInt(alpha, 16) / 255) * 100);
            return percent;
        }
    }
}
