import { autoinject } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { Filter, FilterActivateParams } from '@wsb_dev/datafi-shared/lib/types/Filter';

export interface DateOptions {
    mode: string;
    name: string;
    altInput: boolean;
    altFormat: string;
    dateFormat: string;
    disableMobile: boolean;
}
@autoinject

export class FilterDialog {

    filters: Filter[] = [];

    constructor(
        private dialogController: DialogController,
    ) { }

    activate(model: FilterActivateParams) {
        this.filters = model.filterProperties;
    }

    submit() {
        return this.dialogController.ok(this.filters);
    }
    resetFilter() {
        this.filters.forEach((filter) => {
            if (filter.type === 'object') {
                Object.keys(filter.value).forEach((index) => {
                    filter.value[index] = null;
                });
            }
            else {
                filter.value = [];
            }
        });
    }
}
