import { inject } from 'aurelia-dependency-injection';
import { DialogController } from 'aurelia-dialog';

export type ConfirmDialogActivate  = Partial<ConfirmDialog>;

@inject(
    DialogController,
)
export class ConfirmDialog {
  message: string;
  title: string;

  constructor(private dialog: DialogController){}

  activate(model: Partial<ConfirmDialog>){
      Object.assign(this, model);
  }
}
