import { TDashboardItemType } from '../../types/Dashboards';
import { ProgramConfig } from '@wsb_dev/datafi-shared/lib/types';
import { autoinject, bindable, PLATFORM } from 'aurelia-framework';
import { createCustomEvent } from '../../util/events/createCustomEvent';

import { ChartWrapper } from '../../components/chart-wrapper/chart-wrapper';
import { SummaryGadget } from '../../components/summary-gadget/summary-gadget';
import { DashboardTableWrapper } from '../../components/dashboard-table-wrapper/dashboard-table-wrapper';
import { DashboardText } from '../../components/dashboard-text/dashboard-text';
import { MapGadget } from '../../components/map-gadget/map-gadget';
import { FilterGadget } from '../../components/filter-gadget/filter-gadget';

PLATFORM.moduleName('../../components/chart-wrapper/chart-wrapper');
PLATFORM.moduleName('../../components/summary-gadget/summary-gadget');
PLATFORM.moduleName('../../components/dashboard-table-wrapper/dashboard-table-wrapper');
PLATFORM.moduleName('../../components/map-gadget/map-gadget');
PLATFORM.moduleName('../../components/dashboard-text/dashboard-text');
PLATFORM.moduleName('../../components/filter-gadget/filter-gadget');

@autoinject
export class DashboardGrid {
    @bindable config: ProgramConfig;
    @bindable editEnabled: boolean;
    @bindable editItem: any;

    constructor(
        private element: Element,
    ) {}

    components: Partial<Record<TDashboardItemType, any>>= {
        map: MapGadget,
        chart: ChartWrapper,
        table: DashboardTableWrapper,
        summary: SummaryGadget,
        text: DashboardText,
        filter: FilterGadget,
    }

    editItemChanged() {
        return this.config.data.items.forEach((item) => {
            item.highlight = this.editItem === item;
        });
    }

    dispatchEvent(eventName: string, data: Record<string, any>) {
        this.element.dispatchEvent(createCustomEvent(eventName, data, true));
    }

}
