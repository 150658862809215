import { bindable, inject } from 'aurelia-framework';
import { BulkEdits } from '../bulk-edit-dialog/bulk-edit-dialog';
import { ActionService } from '../../services/actions/ActionService';
import { DatafiProAPI } from '../../services/api/DatafiProAPI';
import { Field } from '@wsb_dev/datafi-shared/lib/types';

@inject(DatafiProAPI, ActionService)
export class DistinctValueDropdown {
    @bindable fields: Field[] = [];
    @bindable values: string[] | number[];
    @bindable selectedField = '';
    @bindable programId: number;
    @bindable bulkEdits: BulkEdits;

    @bindable options: string[] = [];

    constructor(
        private api: DatafiProAPI) {
    }

    attached() {
        this.options = this.fields.map((field) => {
            return field.name;
        });
    }

    selectedFieldChange(newSelectedField) {
        this.values = [];
        this.selectedField = newSelectedField;

        const fieldObject = this.fields.find((field) => {
            if (field.name === newSelectedField) {
                return true;
            } else {
                return false;
            }
        });

        if (fieldObject?.options) {
            this.values = fieldObject.options.map((option) => {
                return option.value;
            });
            return Promise.resolve();
        } else {
            return this.api.projects.find({
                query: {
                    $limit: 200,
                    $modify: {
                        distinctValue: [this.programId, newSelectedField],
                    },
                    program_id: this.programId,
                },
            }).then((fieldValues: any) => {
                this.values = [];
                fieldValues.data.forEach((item: Record<string, never>) => {
                    return this.values.push(...Object.values(item));
                });
                return this.values;
            }).then(() => {
                return;
            });
        }
    }

    valueInputChange(valueInput) {
        this.bulkEdits.field = this.selectedField;
        this.bulkEdits.value = valueInput;
    }
}
