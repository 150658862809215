import { LogManager, autoinject, bindable } from 'aurelia-framework';
import { Chart, ChartConfiguration, registerables } from 'chart.js';
// need this for time axes
import 'chartjs-adapter-luxon';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import * as _ from 'lodash';

const log = LogManager.getLogger('dfp:chart');

@autoinject
export class DashboardChart {
    @bindable datalabels: boolean
    @bindable config: ChartConfiguration;

    resolved = false;
    @bindable chart: Chart;
    context: CanvasRenderingContext2D;
    canvas: HTMLCanvasElement;

    attached() {
        this.resolved = true;
        Chart.register(...registerables);
        Chart.register(ChartDataLabels);
        this.context = this.canvas.getContext('2d');
        this.chart = new Chart(this.context, this.getConfig());
    }

    configChanged() {
        if (this.chart && this.context) {
            this.getConfig();
            this.chart.config.data = this.config.data;
            this.chart.config.options = this.config.options;
            this.chart.config.type = this.config.type;
            this.chart.update('normal');
        }
    }

    getConfig(){
        if(this.config && this.config?.type !== 'bar'){
            this.config.options.scales = {};
        }
        return this.config;
    }

    detached() {
        log.debug('Removing chart: ', this.chart);
        if (this.chart) {
            this.chart.destroy();
            this.chart = null;
        }
    }
}
