import { autoinject, LogManager } from 'aurelia-framework';
import { StoreManager } from '../../services/util/StoreManager';
import { SimpleStore } from '../../services/util/SimpleStore';
import { subscribe } from '../../util/decorators/subscribe';
import { colorThemes, getColorTheme } from '../../util/colorThemes/getColorTheme';
import { IDashboardChartConfig, IDashboardItem } from '../../types/Dashboards';
import { Stats, statsAndLabels, totalCountAndLabels } from '../../components/chart-editor/utils/chart-editor-utils';
import { EventAggregatorWrapper } from '../../util/events/eventAggregatorWrapper';
import { titleize } from '@wsb_dev/datafi-shared/lib/util/strings/titleize';
import _ from 'lodash';
import { AlertService } from 'services/util/Alert';
const logger = LogManager.getLogger('dfp:chart-wrapper');
@autoinject
@subscribe({
    events: [
        { eventEmitter: 'ea', event: 'update-chart', fn: 'updateData' },
    ],
})
@subscribe({
    events: [
        { eventEmitter: 'store', event: SimpleStore.change, fn: 'onStoreChange' },
    ], attached: 'dataStoreAttached', detached: 'dataStoreDetached',
})
export class ChartWrapper {
    chartOptions: any;
    resolved = false;
    // subscriber for the event emitter
    store: SimpleStore;
    config: IDashboardChartConfig;
    id: string;
    datalabels: boolean;
    wrapperEditItem = undefined;

    constructor(
        private ea: EventAggregatorWrapper,
        private stores: StoreManager,
        private alerts: AlertService,
    ) {
    }

    unbind() {
        this.dataStoreDetached();
    }

    onStoreChange() {
        this.updateData(this.wrapperEditItem, true);
    }

    dataStoreAttached() { return; }
    dataStoreDetached() { return; }

    async activate(model) {
        this.id = model.options.id;
        this.config = model.options.config;
        // remove object reference and deep clone the original options
        this.wrapperEditItem = _.cloneDeep(model.options);
        this.updateData();
    }

    async updateData(item?: IDashboardItem<IDashboardChartConfig>, storeChanged?: boolean): Promise<void> {
        logger.debug('updating chart data', item);
        if (item && item.id !== this.id || item && item?.id === undefined) {
            return;
        }

        let itemDeep = _.cloneDeep(item);

        // // this keeps getting set to undefined somewhere and I'm not sure why
        // issues are arising where certain field properties are being dynamically set to undefined
        const unsetFields = ['config.splitField.type', 'config.statsField.type', 'config.categoryField.type',
            'config.splitField.parent', 'config.statsField.parent', 'config.categoryField.parent',
            'config.splitField.format', 'config.statsField.format', 'config.categoryField.format',
        ];
        if (itemDeep?.config?.customTitle == undefined || this.wrapperEditItem?.config?.customTitle == undefined) {
            unsetFields.push('config.customTitle');
        }

        /* might need to change this approach, we should probably look into only emitting an event if the config changes,
        because this deep checking is unreliable with these complex configurations */
        itemDeep = this.omitter(itemDeep, unsetFields);
        this.wrapperEditItem = this.omitter(this.wrapperEditItem, unsetFields);
        if (_.isEqual(this.wrapperEditItem, itemDeep) && !storeChanged) {
            logger.debug('no configuration changes, not updating chart');
            return;
        } else {
            this.wrapperEditItem = _.cloneDeep(item);
        }

        this.resolved = false;

        // get the data store
        const store = this.stores.getStore(this.config?.storeId) as SimpleStore;
        if (!store) {
            this.resolved = true;
            return;
        }

        if (this.store !== store) {
            // if its a different store, call current store detached function
            this.dataStoreDetached();
            this.store = store;
            await this.store.refresh()
                .catch((err) => {
                    this.resolved = false;
                    this.alerts.create({
                        level: 'error',
                        label: `Error fetching DataStore: ${this.store.name} (${err.message})`,
                        dismissable: true,
                    });
                });
            this.dataStoreAttached();
        }

        // ensure we don't modify the config
        const config = JSON.parse(JSON.stringify(this.config));
        this.datalabels = config?.datalabels;

        this.chartOptions = config?.chartOptions;

        const currentField = config.categoryField || { label: 'None', name: 'none' };

        if (currentField.type !== 'date') {
            config.dateRange = '';
        }

        // this is the data that will be used for everything
        const data = this.store?.data || [];

        let chartjs_labels_data = undefined;

        // do the initial grouping
        const grouped = _.mapValues(_.groupBy(data, (item) => _.get(item, currentField.path)));

        if (config.chartOptions.type === 'bar' && config.splitField && config.splitField.name !== 'none') {
            // do another grouping
            const grouped2 = _.mapValues(_.groupBy(data, currentField.path), (a, b) => _.groupBy(a, (item) => _.get(item, config.splitField.path)));
            const grouped2Fields = Object.keys(_.mapValues(_.groupBy(data, (item) => _.get(item, config.splitField.path))));
            if (config.stat === 'Count') {
                chartjs_labels_data = totalCountAndLabels(currentField?.type, config.dateRange, grouped2, grouped2Fields, config.splitField.type);
            } else {
                chartjs_labels_data = statsAndLabels(currentField?.type, config.dateRange, grouped2, config.statsField.name, <Stats>config.stat, grouped2Fields, config.splitField.type, config.statsField.path);
            }
        } else {
            // just one grouping
            if (config.stat === 'Count') {
                chartjs_labels_data = totalCountAndLabels(currentField?.type, config.dateRange, grouped, undefined);
            } else {
                chartjs_labels_data = statsAndLabels(currentField?.type, config.dateRange, grouped, config.statsField.name, <Stats>config.stat);
            }
        }

        // pass our new data into this function that will update the chart configuration
        this.setChartData(chartjs_labels_data.labels, currentField.label, chartjs_labels_data.data);
        this.resolved = true;

        return;
    }

    setChartData(labels: Array<string | number>, label: string, data: any[]): void {
        logger.debug('setting chart data', labels, label, data);
        this.chartOptions.data.labels = labels;

        // handle charts made before color themes were added
        if (!this.config.chartTheme) {this.config.chartTheme = colorThemes[0];}

        const theme = getColorTheme(data.length, this.config.chartTheme);

        // single dataset
        if (typeof data[0] !== 'object') {
            this.chartOptions.data.datasets[0].label = label;
            this.chartOptions.data.datasets[0].data = data;
            // if it is a bar chart and we only have one dataset, just use a single color
            if (this.config.chartOptions.type === 'bar') {
                theme.primary = [theme.primary[0]];
                theme.accent = [theme.accent[0]];
            }
            this.chartOptions.data.datasets[0].backgroundColor = theme.primary;
            this.chartOptions.data.datasets[0].color = theme.accent;

            // multiple datasets
        } else {
            // match each interpolated color to a dataset
            for (let i = 0; i <= data.length - 1; i++) {
                data[i]['backgroundColor'] = theme.primary[i];
                data[i]['color'] = theme.accent[i];
            }
            this.chartOptions.data.datasets = data;

        }

        // field might be missing a label, in that case use the field name
        const categoryName = this.config.categoryField?.label !== undefined ? this.config.categoryField?.label : this.config.categoryField?.name;
        const statsName = this.config.statsField?.label !== undefined ? this.config.statsField?.label : this.config.statsField?.name;

        if (this.config.categoryField?.type === 'date') {
            // if there is a time interval, specify it in the scale options
            _.set(this.chartOptions, 'options.scales.x', {
                stacked: this.config.stacked, title: {
                    display: true, text: categoryName,
                    font: { size: 16, weight: 'bold' },
                },
                type: 'time', time: { unit: this.config.timeInterval },
            });
        } else {
            _.set(this.chartOptions, 'options.scales.x', {
                stacked: this.config.stacked, title: {
                    display: true, text: categoryName,
                    font: { size: 16, weight: 'bold' },
                },
            });
        }
        // set y axis label
        const yField = !this.config.statsField || this.config.statsField.name === 'none' ? categoryName : statsName;
        _.set(this.chartOptions, 'options.scales.y', {
            stacked: this.config.stacked, title: {
                display: true,
                text: `${titleize(this.config.stat)} of ${yField}`,
                font: { size: 16, weight: 'bold' },
            },
        });

        _.set(this.chartOptions, 'options.plugins.title', {
            display: true, text: this.config.title, font: { size: 20, weight: 'bold' },
        });

        if (this.datalabels) {
            const dataLabelOptions = {
                align: 'center',
                anchor: 'center',
                backgroundColor: (context) => {
                    return context.dataset.backgroundColor;
                },
                borderColor: (context) => {
                    return context.dataset.color;
                },
                borderRadius: 25,
                borderWidth: 2,

                color: (context) => {
                    return context.dataset.color;
                },
                font: {
                    weight: 'bold',
                },
                padding: 6,

            };

            if (this.config.percentLabels) {
                if(this.config.splitField.name !== 'none'){
                    // use grand total in chart for multiple datasets
                    dataLabelOptions['formatter'] = (value, context) => {
                        const grandTotal = context.chart.data.datasets.map((ds) => ds.data.reduce((a,z) => a + z)).reduce((a, z) => a + z);
                        return ((context.dataset.data[context.dataIndex] / grandTotal) * 100).toFixed(1) + '%';
                    };
                    dataLabelOptions['display'] = (context) => {
                        // if the value is bigger than this, it'll show up
                        const grandTotal = context.chart.data.datasets.map((ds) => ds.data.reduce((a, z) => a + z)).reduce((a, z) => a + z);
                        if (!((context.dataset.data[context.dataIndex] / grandTotal) * 100 > 2.5)) {return false;}
                        else { return 'auto';}
                    };
                } else{
                    dataLabelOptions['formatter'] = (value, context) => {
                        const sum = context.dataset.data.reduce((a, b) => a + b);
                        return ((context.dataset.data[context.dataIndex] / sum) * 100).toFixed(1) + '%';
                    };
                    dataLabelOptions['display'] = (context) => {
                        // if the value is bigger than this, it'll show up
                        const sum = context.dataset.data.reduce((a, b) => a + b);
                        if (!((context.dataset.data[context.dataIndex] / sum) * 100 > 5)) {return false;}
                        else {return 'auto';}
                    };
                }
            } else {
                // default to displaying the count
                dataLabelOptions['formatter'] = (value, context) => {
                    return context.dataset.data[context.dataIndex].toFixed(1);
                };
                dataLabelOptions['display'] = (context) => {
                    // if the value is bigger than this, it'll show up
                    if (!(context.dataset.data[context.dataIndex] > 0)) {return false;}
                    else {return 'auto';}
                };
            }

            _.set(this.chartOptions, 'options.plugins.datalabels', dataLabelOptions);

        } else {
            _.set(this.chartOptions, 'options.plugins.datalabels', {
                // display nothing
                display: (context) => {
                    return null;
                },
            });
        }

        return this.chartOptions;
    }

    // function for emitting certain fields
    omitter(item, fields: string[]): any {
        fields.forEach((f) => {
            _.unset(item, f);
        });
        return item;
    }

}
