import { bindable } from 'aurelia-framework';

export class DashboardLayout {
    @bindable isMobile: boolean;
    mediaQuery: MediaQueryList;

    constructor() {
        this.mediaQuery = window.matchMedia('(max-width: 600px)');
        this.handleMediaQuery(this.mediaQuery);

        this.mediaQuery.addEventListener('change', this.handleMediaQuery);
    }

    handleMediaQuery = (evt) => {
        return evt.matches ? this.isMobile = true : this.isMobile = false;
    }
}
