
import { autoinject, bindable, PLATFORM } from 'aurelia-framework';
import { ChartEditor } from '../../components/chart-editor/chart-editor';
import { ListGadgetEditor } from '../../components/list-gadget-editor/list-gadget-editor';
import { TableGadgetEditor } from '../../components/table-gadget-editor/table-gadget-editor';
import { TextGadgetEditor } from '../../components/text-gadget-editor/text-gadget-editor';
import { DashboardEditor } from '../../components/dashboard-editor/dashboard-editor';
import { SummaryGadgetEditor } from '../../components/summary-gadget-editor/summary-gadget-editor';
import { MapGadgetEditor } from '../../components/map-gadget-editor/map-gadget-editor';
import { FilterGadgetEditor } from '../../components/filter-gadget-editor/filter-gadget-editor';

PLATFORM.moduleName('../../components/chart-editor/chart-editor');
PLATFORM.moduleName('../../components/list-gadget-editor/list-gadget-editor');
PLATFORM.moduleName('../../components/table-gadget-editor/table-gadget-editor');
PLATFORM.moduleName('../../components/text-gadget-editor/text-gadget-editor');
PLATFORM.moduleName('../../components/dashboard-editor/dashboard-editor');
PLATFORM.moduleName('../../components/summary-gadget-editor/summary-gadget-editor');
PLATFORM.moduleName('../../components/map-gadget-editor/map-gadget-editor');
PLATFORM.moduleName('../../components/filter-gadget-editor/filter-gadget-editor');

@autoinject
export class GadgetEditor {
    @bindable editItem: any;

    components = {
        default: '',
        map: MapGadgetEditor,
        chart: ChartEditor,
        list: ListGadgetEditor,
        table: TableGadgetEditor,
        text: TextGadgetEditor,
        dashboard: DashboardEditor,
        summary: SummaryGadgetEditor,
        filter: FilterGadgetEditor,
    }
}
