import { autoinject } from 'aurelia-dependency-injection';
import { DateOptions } from '../../components/filter-dialog/filter-dialog';
import { IFilterConfig, IFilterOptions } from '../../types/Dashboards';
import { subscribe } from '../../util/decorators/subscribe';
import { EventAggregatorWrapper } from '../../util/events/eventAggregatorWrapper';
import { SimpleStore } from '../../services/util/SimpleStore';
import { StoreManager } from '../../services/util/StoreManager';
import { filtersToQuery } from '@wsb_dev/datafi-shared/lib/util/fields/filtersToQuery';

@autoinject
@subscribe({
    events: [{ eventEmitter: 'eaw', event: 'edit-item', fn: 'refresh' }],
})
export class FilterGadget {
    dateOptionsTypes = ['single', 'range'];
    dateOptions: Record<string, DateOptions> = {
        single: {
            mode: 'single', name: 'Single Date', altInput: true, altFormat: 'F j, Y', dateFormat: 'Y-m-d', disableMobile: true,
        },
        range: {
            mode: 'range', name: 'Date Range', altInput: true, altFormat: 'F j, Y', dateFormat: 'Y-m-d', disableMobile: true,
        },
    }

    predefinedDates = [
        { name: 'none', label: 'No Filter' },
        { name: 'today', label: 'Today', type: 'TODAY' },
        { name: 'this_week', label: 'This Week', type: 'THISWEEK' },
        { name: 'this_month', label: 'This Month', type: 'THISMONTH' },
        { name: 'this_year', label: 'This Year', type: 'THISYEAR' },
        { name: 'custom', label: 'Custom Date' },
    ]
    selection = this.predefinedDates[0];

    query = [];
    customQuery = { mode: 'single', date: undefined, checked: false };

    filters: IFilterConfig;
    storeData: SimpleStore[];

    constructor(
        private eaw: EventAggregatorWrapper,
        private stores: StoreManager,
    ) { }

    async getStoreData() {
        this.storeData = this.filters?.config?.storeIds.map((id) => {
            return this.stores.getStore(id) as SimpleStore;
        }).filter(((store) => !!store));

        this.storeData?.forEach((store) => {
            if (store.query?.$modify?.date && !this.query.length) {
                store.resetQuery();
            }
        });
    }

    async activate(model: IFilterConfig) {
        this.filters = model?.options;
        this.getStoreData();
    }

    /**
     * This function handles selection of date dropdown filter change (both for predefined & custom date)
     * @param date predefined or custom predefinedDates
     * @param filter filter where selection was made
     */

    onDateChange(date: Record<string, any>, filter: IFilterOptions) {

        filter.data.forEach((item) => {
            this.query = [];

            if (this.customQuery.checked) {
                // reset custom query if other predefined date selected
                this.customQuery.checked = false;
                this.queryStoreData(item.storeId);
            }

            if (!item.field) {
                return;
            }

            switch (date.name) {
            case 'custom':
                this.customQuery.checked = true;
                this.changeCustomQuery(this.customQuery.date, filter);
                break;
            case 'today':
            case 'this_week':
            case 'this_month':
            case 'this_year':
                if (item.field.path.startsWith('metadata.')) {
                    this.changeCustomQuery(this.getDateRange(date.name), filter);
                    return;
                }
                this.query.push(item.field.path, date.type);
                this.queryStoreData(item.storeId, this.query);
                break;
            default: // resets filter
                this.queryStoreData(item.storeId);
            }
        });
    }
    /**
     * After a custom date selection is made, or if the custom query is changed,
     * this function puts together and returns a query.
     * The function also checks if the date string is a range or single selection
     * @param date actual date string
     * @param filter filter where selection was made
     * @returns store query
     */
    changeCustomQuery(date: string, filter: IFilterOptions) {
        if (!date || !filter) {
            return;
        }

        filter.data.forEach((item) => {
            let query: Record<string, any> = {};

            if (date.includes('to') || this.customQuery.mode === 'single') {
                query = filtersToQuery([{ type: item.field.type, name: item.field.path, value: date }]);
            }

            if (Object.keys(query).length) {
                return this.storeData?.forEach((store) => {
                    if (store.id === item.storeId) {
                        if (query.metadata) {
                            query.metadata = {
                                ...store.query.metadata,
                                ...query.metadata,
                            };
                        }

                        const builtQuery = {
                            ...store.query,
                            ...query,
                            $modify: {
                                ...store.query.$modify,
                                date: [],
                            },
                        };

                        store.setQuery(builtQuery);
                    }
                });
            }
        });
    }

    getDateRange(dateSelection) {
        const now = new Date().toISOString();
        const morning = new Date(new Date().setHours(0, 0, 0, 0));
        const getWeek = new Date(new Date(morning).setDate(morning.getDate() - (morning.getDay() === 0 ? 6 : morning.getDay())));
        const getMonth = new Date(morning.getFullYear(), morning.getMonth(), 1);
        const getYear = new Date(new Date().getFullYear(), 0, 1);

        if (dateSelection === 'today') {
            return `${morning.toISOString()} to ${now}`;
        } else if (dateSelection === 'this_week') {
            return `${getWeek.toISOString()} to ${now}`;
        } else if (dateSelection === 'this_month') {
            return `${getMonth.toISOString()} to ${now}`;
        } else if (dateSelection === 'this_year') {
            return `${getYear.toISOString()} to ${now}`;
        }
    }

    /**
     * After a predefined date selection is made, the function puts together and returns a query.
     * Store query is reset if filter is reset
     * @param storeId
     * @param query
     * @returns store query
     */
    queryStoreData(storeId: number, query?: Record<string, any>) {
        if (!this.storeData) {
            this.getStoreData();
        }

        return this.storeData?.forEach((store) => {
            if (store.id === storeId && query) {
                store.setQuery({
                    ...store.query,
                    $modify: {
                        ...store.query.$modify,
                        date: [query],
                    },
                });
            }
            if (store.id === storeId && !query) {
                store.resetQuery();
            }
        });
    }
    /**
     * Function called if data has changed
     * @param filter filter gadget
     */
    refresh(filter: IFilterOptions) {
        if (filter.id === this.filters.id) {
            this.filters = filter;
        }
    }
}
