import { Filter } from '@wsb_dev/datafi-shared/lib/types';
import { bindable, bindingMode } from 'aurelia-framework';
import { DateOptions } from '../filter-dialog/filter-dialog';

export class FilterBuilder {
    @bindable({mode: bindingMode.twoWay}) filters: Filter[] = [];
    dateOptionsTypes = ['single', 'range'];
    dateOptions: Record<string, DateOptions> = {
        single: {
            mode: 'single', name: 'Single Date', altInput: true, altFormat: 'F j, Y', dateFormat: 'Y-m-d', disableMobile: true,
        },
        range: {
            mode: 'range', name: 'Date Range', altInput: true, altFormat: 'F j, Y', dateFormat: 'Y-m-d', disableMobile: true,
        },
    }

    filtersChanged() {
        if (this.filters) {
            this.filters.forEach((filter) => {
                switch (filter.type) {
                case 'checkbox':
                    if (!filter.value) {
                        filter.value = [];
                    }
                    break;
                case 'object':
                    if (!filter.value) {
                        filter.value = {};
                    }
                    break;
                }
            });
        }
    }
}
