import { Field } from '@wsb_dev/datafi-shared/lib/types';
import { autoinject } from 'aurelia-dependency-injection';
import { SimpleStore } from '../../services/util/SimpleStore';
import { StoreManager } from '../../services/util/StoreManager';
import { IDashboardItem, IFilterConfig } from '../../types/Dashboards';
import { EventAggregator } from 'aurelia-event-aggregator';
import { AlertService } from '../../services/util/Alert';
@autoinject

export class FilterGadgetEditor {
    datastores: SimpleStore[];
    programId: number;
    filterTypes = ['Date'] // this list will eventually expand to other types
    globalFilters: IDashboardItem<IFilterConfig>

    constructor(
        private stores: StoreManager,
        private ea: EventAggregator,
        private alerts: AlertService,
    ) { }

    async activate(model): Promise<void> {
        this.globalFilters = model;
        this.programId = this.stores.programId;
        this.datastores = this.stores.stores;
    }

    newFilter(type): void {
        if (!this.globalFilters.config) {
            this.globalFilters.config = {};
            this.globalFilters.config.options = [];
            this.globalFilters.config.storeIds = [];
        }

        const filterTypeExists = this.globalFilters.config.options.find((filter) => filter.type === type);
        if (!filterTypeExists) {
            this.globalFilters.config.options.push({
                type: type,
                label: 'New Filter',
                id: `${type}-${this.globalFilters.config.options.length}`,
            });
            this.ea.publish('edit-item', this.globalFilters);
        } else {
            this.alerts.create({
                label: `${type} filter is already being used`,
                level: 'warning',
                dismissable: true,
            });
        }
    }

    newFilterStore(filterId: string, datastore: SimpleStore): void {
        const filter = this.globalFilters.config.options.find((filter) => filter.id === filterId);
        const duplicate = this.globalFilters.config.storeIds.some((id) => id === datastore.id);

        if (!filter.data) {
            filter.data = [];
        }

        if (!duplicate) {
            this.globalFilters.config.storeIds.push(datastore.id);
            filter.data.push({
                id: `store-${datastore.id}-${filterId}`,
                label: datastore.name,
                storeId: datastore.id,
            });
        } else {
            this.alerts.create({
                label: `${datastore.name} is already being used`,
                level: 'warning',
                dismissable: true,
            });
        }
    }

    updateFilterStore(filterId: string, field: Field, dataId: string): void {
        this.globalFilters.config.options.forEach((filter) => {
            if (filter.id === filterId) {
                filter.data?.forEach((item) => {
                    if (item.id === dataId) {
                        item.field = {
                            name: field.name,
                            type: field.type,
                            label: field.label,
                            path: field.path,
                        };
                    }
                });
            }
        });
    }

    removeFilter(index: number, data) {
        data?.forEach((item) => {
            const storeId = Number(item.id.split('-')[1]);
            this.globalFilters.config.storeIds = this.globalFilters.config.storeIds.filter((id) => id !== storeId);
        });
        this.globalFilters.config.options.splice(index, 1);
    }

    removeDataStore(index: number, filterId: string, id: string): void {
        const storeId = Number(id.split('-')[1]);
        this.globalFilters.config.storeIds = this.globalFilters.config.storeIds.filter((id) => id !== storeId);

        this.globalFilters.config.options.forEach((filter) => {
            if (filter.id === filterId) {
                filter.data?.forEach((item) => {
                    if (item.id === id) {
                        filter.data.splice(index, 1);
                    }
                });
            }
        });
        this.globalFilters.config.storeIds;
    }

    getDateFields(datastoreId: number): Field[] {
        const store = this.stores.getStore(datastoreId);
        return store ? store.fields.filter((fields: Field) => fields.type === 'date') : [];
    }
}
