import { autoinject } from 'aurelia-dependency-injection';
import { DialogController } from 'aurelia-dialog';
import { FormSubmission } from '@wsb_dev/datafi-shared/lib/types/FormSubmission';
import { DatafiProAPI } from '../../services/api/DatafiProAPI';

export interface SubmissionStatus {
    type: 'success' | 'pending' | 'error';
    title: 'Success' | 'Pending...' | 'Error';
    message?: string;
}

@autoinject
export class FormSubmissionDialog {
    constructor(
        private dialog: DialogController,
        private api: DatafiProAPI,
    ) { }
    submissionStatus: SubmissionStatus;

    async activate(model: { id: number, query: Record<string, any> }): Promise<void> {
        this.submissionStatus = {
            type: 'pending',
            title: 'Pending...',
            message: 'Your form is submitting. Please wait...',
        };

        const result = model?.id ?
            this.api.formSubmissions.patch(model.id, model.query) :
            this.api.formSubmissions.create(model.query) as Promise<FormSubmission>;

        result.then(() => {
            this.submissionStatus = {
                type: 'success',
                title: 'Success',
                message: 'Form submitted. Closing this dialog will return to Datafi Pro.',
            };
        }).catch((err) => {
            this.submissionStatus = {
                type: 'error',
                title: 'Error',
                message: err.message,
            };
        });
    }
}
