import { EventAggregator } from 'aurelia-event-aggregator';
import { colorThemes } from '../../util/colorThemes/getColorTheme';
import { autoinject, bindable, LogManager } from 'aurelia-framework';
import { ChartConfiguration, TimeUnit } from 'chart.js';
import { StoreManager } from '../../services/util/StoreManager';
import { SimpleStore } from '../../services/util/SimpleStore';
import { Field } from '@wsb_dev/datafi-shared/lib/types';
import { Stats } from './utils/chart-editor-utils';
import { IDashboardChartConfig, IDashboardItem } from '../../types/Dashboards';
import _ from 'lodash';
import { AlertService } from 'services/util/Alert';
const logger = LogManager.getLogger('dfp:chart-editor');
@autoinject
export class ChartEditor {
    @bindable editItem: IDashboardItem<IDashboardChartConfig>;
    currentEditItem: IDashboardItem<IDashboardChartConfig>;
    chartOptions: ChartConfiguration;
    charts: string[] = ['bar', 'pie', 'doughnut'];
    // this specifies the date group and interval in seconds
    timeframes: string[] = ['day', 'week', 'month', 'quarter', 'year']
    timeInterval: TimeUnit = 'day';
    datasetTitle: string;
    fields: Field[] = [];
    ready: boolean;
    store: SimpleStore;
    ops: Stats[] = ['Minimum', 'Maximum', 'Count', 'Sum', 'Mean'];
    noneField: Field = { label: 'None', name: 'none'};
    rangeOptions = {
        mode: 'range', name: 'Date Range', altInput: true, altFormat: 'F j, Y', dateFormat: 'Y-m-d', disableMobile: true, defaultDate: [],
        onClose:  (selectedDates: Date[], dateString: string): void => {
            this.editItem.config.dateRange = dateString;
            this.publishEditItem();
        },
    };
    selectedDate: string;
    storeLoading = false;
    chartThemes = colorThemes;

    constructor(
        private ea: EventAggregator,
        private element: Element,
        private stores: StoreManager,
        private alerts: AlertService,
    ) { }

    async activate(model: IDashboardItem): Promise<void> {
        this.ready = false;
        await this.loadStore(model.config.storeId, model.config);
        this.editItem = model;
        this.chartOptions = <ChartConfiguration>this.editItem.config?.chartOptions;
        this.datasetTitle = this.chartOptions?.data?.datasets[0]?.label !== undefined ? this.chartOptions.data?.datasets[0]?.label : 'New Dataset';
        this.ready = true;
    }

    async updateFields() {
        if (this.storeLoading) {
            return;
        }

        const config = this.editItem.config;

        _.defaults(config, {
            categoryField: this.noneField,
            splitField: this.noneField,
            statsField: this.noneField,
            datalabels: false,
            chartOptions: {
                type: 'bar',
                data: {
                    datasets: [],
                },
                plugins: [],
            },
        });

        // if we switch out of a date field, reset the date range
        if (config.categoryField?.type !== 'date') {
            config.dateRange = '';
        }

        // only bar charts get the splitfield
        if (config.chartOptions.type !== 'bar') {
            config.splitField = this.noneField;
        }

        config.title = this.generateChartTitle();

        if (config.storeId) {
            await this.loadStore(config.storeId, config);
        }

        this.publishEditItem('update fields');
    }

    // little function for generating the chart title
    generateChartTitle(): string | string[] {
        const config = this.editItem.config;

        if(config.customTitle){
            return config.title;
        }

        let title = '';

        // use label if it exists
        const categoryField = config.categoryField?.label !== undefined ?
            config.categoryField.label.toUpperCase() :
            config.categoryField.name.toUpperCase();
        const statsField = config.statsField?.label !== undefined ?
            config.statsField.label.toUpperCase() :
            config.statsField.name.toUpperCase();
        const splitField = config.splitField?.label !== undefined ?
            config.splitField.label.toUpperCase() :
            config.splitField.name.toUpperCase();

        if (config.stat === 'Count') {
            title = `Record Count vs. ${categoryField}`;
        } else {
            title = `${config.stat} of ${statsField} vs. ${categoryField}`;
        }

        if (config.splitField.name !== 'none') {
            title += ` Split by ${splitField}`;
        }

        if (config.dateRange !== '') {
            // break this up because it gets
            return [title, `for Period ${config.dateRange}`];
        } else {
            return title;
        }
    }

    clearDates(): void {
        this.selectedDate = '';
        this.editItem.config.dateRange = '';
        this.updateFields();
    }

    publishEditItem(event?) {
        if (this.editItem !== undefined) {
            logger.debug('published new chart', event, this.editItem);
            this.ea.publish('update-chart', this.editItem);
        }
    }

    async loadStore(storeId: number, config) {

        const dataStore = this.stores.getStore(config.storeId) as SimpleStore;
        if (dataStore) {
            this.storeLoading = true;
            await dataStore.refresh().then((dataStore) => {
                // remove the metadata field after flattening
                this.fields = dataStore.fields
                    .filter((field) => { return field.name !== 'metadata'; });

                // go ahead and add in a none option
                this.fields.unshift(this.noneField);

                // set the store

                this.store = dataStore;

                // find existing fields by index
                config.categoryField = this.fields.find((f) => f.name === config.categoryField?.name) || this.noneField;
                config.statsField = this.fields.find((f) => f.name === config.statsField?.name) || this.noneField;
                config.chartTheme = this.chartThemes.find((co) => co.label === config.chartTheme?.label) || this.chartThemes[0];

                // ignore split for pie and doughnut charts
                if (config.chartOptions.type !== 'bar') {
                    config.splitField = this.noneField;
                } else {
                    config.splitField = this.fields.find((f) => f.name === config.splitField?.name) || this.noneField;
                }

                config.stat = config.stat !== undefined ? config.stat : 'Count';

                if (config.stat === 'Count') {
                    config.statsField = this.noneField;
                }

                config.datalabels = config.datalabels !== undefined ? config.datalabels : false;
                config.stacked = config.stacked !== undefined ? config.stacked : false;
                config.timeInterval = config.timeInterval !== undefined ? config.timeInterval : 'day';
                config.dateRange = config.dateRange !== undefined ? config.dateRange : '';

                const dr = config.dateRange.split(' ');
                dr.length > 1 ? this.rangeOptions.defaultDate = [dr[0], dr[2]] : [];

                this.storeLoading = false;
            }).catch((err) => {
                this.storeLoading = false;
                this.alerts.create({
                    level: 'error',
                    label: `Error fetching DataStore: ${dataStore.name} (${err.message})`,
                    dismissable: true,
                });
            });
        } else { this.fields.unshift(this.noneField); }
    }
}
