import { bindable, autoinject, PLATFORM } from 'aurelia-framework';
import { Service } from '@feathersjs/feathers';
import { BulkEditDialog } from '../bulk-edit-dialog/bulk-edit-dialog';
PLATFORM.moduleName('../bulk-edit-dialog/bulk-edit-dialog');
import { DialogService } from 'aurelia-dialog';
import { ActiveProgram } from 'services/util/ActiveProgram';
import { Field } from '@wsb_dev/datafi-shared/lib/types';
import { BaseModel } from '@wsb_dev/datafi-shared/lib/types';

@autoinject
export class BulkEditor {
    //Pass into admin-table
    @bindable service: Service<any>;
    @bindable fields: Field[];
    @bindable params: any;
    @bindable selectedRows: BaseModel[];

    constructor(
        public dialogService: DialogService,
        public program: ActiveProgram,
    ) { }

    editSelected(): Promise<void> {
        return this.dialogService.open({
            viewModel: BulkEditDialog,
            model: {
                selectedRows: this.selectedRows,
                fields: this.fields,
                programId: this.program.id,
                service: this.service,
            },
        }).whenClosed((result) => {
            if (result.wasCancelled) { return; }
            else {
                //fire event to refresh the admin table
                this.params = {
                    ...this.params,
                };
            }
        });
    }
}
