import { bindable } from 'aurelia-framework';
import { Map } from 'ol';

export class BasemapToggle {
    @bindable toggleImage: any;
    @bindable map: Map;
    @bindable layerIds: string[];
    basemapLayers: any[];

    @bindable activeBasemap: any;
    @bindable inactiveBasemap: any;

    mapChanged(map) {
        this.basemapLayers = this.map?.getLayers().getArray()
            .filter((l) => {
                return this.layerIds.indexOf(l.get('id')) !== -1;
            });

        this.activeBasemap = this.basemapLayers
            .find((l) => {
                return l.get('visible') === true;
            }) || this.basemapLayers[0];
        this.inactiveBasemap =
            this.basemapLayers
                .find((l) => {
                    return l !== this.activeBasemap;
                });
    }

    activeBasemapChanged(newBasemap) {
        if (newBasemap) {
            newBasemap.setVisible(true);
        }
    }

    inactiveBasemapChanged(newBasemap) {
        if (newBasemap) {
            newBasemap.setVisible(false);
            this.toggleImage = newBasemap.get('thumbnailUrl');
        }
    }

    toggleBasemap() {
        const temp = this.activeBasemap;
        this.activeBasemap = this.inactiveBasemap;
        this.inactiveBasemap = temp;
    }
}
