import { autoinject } from 'aurelia-framework';
import { subscribe } from '../../util/decorators/subscribe';
import { EventAggregatorWrapper } from '../../util/events/eventAggregatorWrapper';

@subscribe({
    events: [{
        eventEmitter: 'ea', event: 'edit-item', fn: 'updateText',
    }],
})

@autoinject
export class DashboardText {
    model;
    innerHTML: string;

    constructor(
        private ea: EventAggregatorWrapper,
    ) {
        this.ea = ea;
    }

    activate(model){
        this.model = model.options;
        this.innerHTML = this.model.config?.data?.innerHTML;
    }

    updateText(newModel){
        if (this.model.id === newModel.id){
            this.innerHTML = newModel.config?.data?.innerHTML;
        }
    }
}
