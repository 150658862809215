import { DialogController } from 'aurelia-dialog';
import { autoinject } from 'aurelia-dependency-injection';
import { bindable } from 'aurelia-framework';
import { Field } from '@wsb_dev/datafi-shared/lib/types/Field';
import { ValidationController, ValidationControllerFactory } from 'aurelia-validation';
import { AlertService } from '../../services/util/Alert';
import { Service } from '@feathersjs/feathers';

export interface BulkEdits {
    field: string;
    value: string;
}
@autoinject

export class BulkEditDialog {
    selectedRows: any[];
    @bindable fields: Field[];
    @bindable program: number;
    @bindable service: Service<any>;
    @bindable bulkEdits: BulkEdits = {
        field: '',
        value: '',
    };
    controller: ValidationController;
    saving: Promise<any>;

    constructor(
        private dialog: DialogController,
        private controllerFactory: ValidationControllerFactory,
        private alerts: AlertService,
    ) { }

    activate(model) {
        this.selectedRows = model.selectedRows;
        this.fields = model.fields;
        this.program = model.programId;
        this.service = model.service;
        this.controller = this.controllerFactory.createForCurrentScope();
    }

    submit() {
        this.saving = this.controller.validate()
            .then((result) => {
                if (!result.valid) {
                    throw new Error('Form is not valid');
                }

                //get the ids for the objects we are updating
                const rowIds = this.selectedRows.map((row) => row.id);

                // batch update those projects based on the bulkEdits field
                return this.service.patch(null, { [this.bulkEdits.field]: this.bulkEdits.value }, {
                    query: {
                        id: { $in: rowIds },
                        program_id: this.program,
                    },
                });

            }).then(() => {
                this.alerts.create({
                    label: 'Edited successfully',
                    level: 'success',
                    dismissable: true,
                });
                this.dialog.ok(this.selectedRows);
            }).catch((e) => {
                this.alerts.create({
                    label: e.message,
                    level: 'error',
                    dismissable: true,
                });
            }).finally(() => this.saving = null);
        return this.saving;
    }
}
