import { bindable, bindingMode } from 'aurelia-framework';
import flatpickr from 'flatpickr';

export class FlatpickrDateField {

    @bindable dateInput: HTMLInputElement;
    @bindable fp: any;
    @bindable options: any = {};
    @bindable({defaultBindingMode: bindingMode.twoWay }) value: Date[];

    attached() {
        this.fp = flatpickr(this.dateInput, this.options);
    }

    optionsChanged(newValue, oldValue) {
        if (this.fp) {

            if (oldValue && newValue.mode !== oldValue.mode) {
                this.fp.clear();
            }
            this.fp.set(this.options, newValue);
        }
    }

    valueChanged(value) {
        if (!value && this.fp) {
            this.fp.clear();
        }
    }

    detached() {
        this.fp.destroy();
    }

}
