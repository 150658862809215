import { autoinject } from 'aurelia-dependency-injection';
import { SimpleStore } from '../../services/util/SimpleStore';
import { StoreManager } from '../../services/util/StoreManager';
import { IDashboardTableConfig } from '../../types/Dashboards';
import { subscribe } from '../../util/decorators/subscribe';
import { EventAggregatorWrapper } from '../../util/events/eventAggregatorWrapper';
import { AlertService } from 'services/util/Alert';

@autoinject
@subscribe({
    events: [{ eventEmitter: 'ea', event: 'edit-item', fn: 'refresh' }],
})
export class DashboardTableWrapper {
    options: IDashboardTableConfig;
    store: SimpleStore | void;

    constructor(
        private stores: StoreManager,
        private ea: EventAggregatorWrapper,
        private alerts: AlertService,
    ) {
    }

    activate(model) {
        this.options = model.options;
        this.refresh();
    }

    refresh() {
        this.store = this.stores.getStore(this.options.storeId);
        if (!this.store) {
            return;
        }
        this.store?.refresh()
            .catch((err) => {
                this.alerts.create({
                    level: 'error',
                    label: `Error fetching DataStore: ${(this.store as SimpleStore<any>).name} (${err.message})`,
                    dismissable: true,
                });
            });
    }
}
